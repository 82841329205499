import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { RestapiService } from 'src/app/services/restapi.service';
import { PLCHandle, WSService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-alarms-viewer',
  templateUrl: './alarms-viewer.component.html',
  styleUrls: ['./alarms-viewer.component.scss'],
  
})
export class AlarmsViewerComponent implements OnInit , OnDestroy {

  @HostBinding('class.ignore') ignore: boolean = false;

  @Input() alarms?:PLCHandle[]
  @Input() alarmsBitMap?:PLCHandle
  @Input() alarmsBitMapLabel: string[] = []
  @Input() reset:PLCHandle;

  constructor(
    private api:RestapiService,
    private wss:WSService
  ) { }
  
  ngOnDestroy(): void {
  }

  ngOnInit() {
    
    // this.allarmi = this.wss.getHandle("ZONA3__Allarmi")
    // for (var x in this.alarms) {
    //   this.alarms[x] = this.wss.getHandle(x)
    //   //a = this.wss.variables[a.name]
    // }
    // this.alarms.forEach(a => {
    // })
    this.ignore = false
  }

  async onReset() {
    
    this.api.write(this.reset, true)
    await setTimeout(x => {
      this.api.write(this.reset, false)
    },500)
  }
}
