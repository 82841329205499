<div *ngIf="lamp" class="label topleft" >
    <div class="lamp" [ngClass]="{'bg-success': lamp.value, 'bg-danger': !lamp.value}"></div>
</div>

<div class="bar"></div>

<div *ngIf="counterSet" class="label text-white">
    &nbsp;di {{counterSet.value}}
</div>


<div class="d-flex flex-column align-items-center">
    <img *ngIf="model.modes" src="../../../../assets/{{getActiveModeIcon()}}">
    <span>
        <span *ngIf="counterActual " class=" text-white">
            {{counterActual.value}} Pz
        </span>
        <span *ngIf="status" class="status text-white">
                {{status.value}}
        </span>
    </span>
</div>

<div class="bar"></div>

<div class="label bottomright">{{model.name}}</div>