import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trafficlight',
  templateUrl: './trafficlight.component.html',
  
})
export class TrafficlightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
