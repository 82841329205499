import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appToggleVariable]'
})
export class ToggleVariableDirective {

  @HostListener('click') onMouseEnter() {
    // this.highlight('yellow');
  }
  
  constructor() { }
  
  
}
