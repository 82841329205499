import { PLCSubscription } from 'src/app/services/restapi.service'
import { PLCHandle } from 'src/app/services/ws.service'

export class Alarm {
    name: string
    address: string
    message: string
    subscription? : PLCSubscription
    plc: string
}


export class MotorSetting {
    start: PLCHandle
    stop: PLCHandle
    feedbackRunMan: PLCHandle
    feedbackRunAuto: PLCHandle
    feedbackRunOn: PLCHandle
}

export class PLCVar {
    label?: string 
    plc: string
    address: string
    clazz?: string
    um?: string
    length?: number
    subscription?: PLCSubscription
    name?:string
    value?:any
}

export class PLCStringVar extends PLCVar {

}

export class PLCBooleanVar extends PLCVar {
}

export class Lamp extends PLCBooleanVar {
    align: string
}

export class TrafficLight {
    public id:string
    public value?:string
}

export class MapTrafficLight extends TrafficLight {
    public x:number
    public y:number
}

export class MapConfig {
    trafficlights:MapTrafficLight[]
    conveyors:Conveyor[]
    chains:Chain[]
    areas?:Area[]
}


export class MapArea {
    x:number
    y:number
    w:number
    h:number
    name: string
}

export class Machine extends MapArea {
    variable?:PLCHandle
    highlighted?: boolean
    icon?: string           // for actions only
    run?: PLCHandle     // for actions only
    running?: PLCHandle     // for actions only
    lamp?: Lamp
    status?: PLCHandle
    statuses?: PLCHandle[]
    alarms?: PLCHandle[]
    automatic?: PLCHandle
    highlight? : PLCHandle
    disabled?:boolean
    modes?: {
        name: string
        icon: string
        variable: PLCHandle
    }[]
    switches?: [{
        variable: PLCHandle
        feedback: PLCHandle 
        subscription?: PLCSubscription
    }]
    buttons?: [{
        label: String
        variable: PLCHandle
        feedback?: PLCVar
        subscription?: PLCSubscription
    }]
    pushbuttons?: [{
        label: String
        variable: PLCHandle
        feedback?: PLCVar
        subscription?: PLCSubscription
    }]
    label?: PLCVar
    counterActual?: PLCHandle
    counterSet?: PLCHandle
    continuos?: PLCHandle
    start?: PLCHandle

}

export class Action extends Machine {

}

export class Area extends Machine {

}

export class Conveyor extends Machine {

}


export class Chain extends Machine {
    backward?:boolean
}