import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrafficlightComponent } from './trafficlight/trafficlight.component';
import { MapComponent } from './map/map.component';
import { ConveyorComponent } from './conveyor/conveyor.component';
import { ChainComponent } from './chain/chain.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { PlcInputComponent } from './plc-input/plc-input.component';
import { PlcInputDoubleComponent } from './plc-input-double/plc-input-double.component';
import { AbstractMachine } from './abstract-component';

import { FormsModule } from '@angular/forms';
import { PlcInputBooleanComponent } from './plc-input-boolean/plc-input-boolean.component';
import { PlcInputIntegerComponent } from './plc-input-integer/plc-input-integer.component';
import { PlcInputShortComponent } from './plc-input-short/plc-input-short.component';
import { AreaComponent } from './area/area.component';
import { MotorControlComponent } from './motor-control/motor-control.component';
import { PlcInputFloatComponent } from './plc-input-float/plc-input-float.component';
import { AlarmButtonComponent } from './alarm-button/alarm-button.component';
import { ScreensaverComponent } from './screensaver/screensaver.component';
import { IoComponent } from './io/io.component';
import { ActionComponent } from './action/action.component';
import { ConfigOutgoComponent } from './config-outgo/config-outgo.component';
import { AlarmsViewerComponent } from './alarms-viewer/alarms-viewer.component';
import { PushButtonComponent } from './buttons/push-button/push-button.component';
import { ButtonSwitchComponent } from './buttons/button-switch/button-switch.component';

@NgModule({
    declarations: [AbstractMachine, TrafficlightComponent, MapComponent, ConveyorComponent, ChainComponent, ContextMenuComponent, NavbarComponent, PlcInputComponent, PlcInputDoubleComponent, PlcInputBooleanComponent, PlcInputIntegerComponent, PlcInputShortComponent, AreaComponent, MotorControlComponent, PlcInputFloatComponent, AlarmButtonComponent, ScreensaverComponent, IoComponent, ActionComponent, ConfigOutgoComponent, AlarmsViewerComponent,PushButtonComponent, ButtonSwitchComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
  exports: [TrafficlightComponent, MapComponent,ConveyorComponent,NavbarComponent,PlcInputComponent, PlcInputDoubleComponent, PlcInputBooleanComponent,PlcInputIntegerComponent,PlcInputShortComponent,AreaComponent, MotorControlComponent,PlcInputFloatComponent,ScreensaverComponent, ConfigOutgoComponent,AlarmsViewerComponent,PushButtonComponent,ButtonSwitchComponent]
})
export class PartialsModule { }
