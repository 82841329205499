import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { io, Socket } from 'socket.io-client';

export class PLCHandle {
    name:string
    plc?:string
    value?:any
    um?:string
    label?:string
    index ?:number
}

@Injectable({
    providedIn: 'root'
})
export class WSService {
    
    socket: Socket;
    public variables: {} = {};
    change$:Subject<PLCHandle> = new Subject<PLCHandle>()

    constructor() {
        this.start()
    }

    getHandle(name):PLCHandle {
        // ends with[0]
        // var r = /\[\d\]$/
        // if (r.test(name)) {
        //     var i = 1
            
        //     var n = name.replace(/\[\d\]$/, "")
        //     if (!this.variables[name])
        //         this.variables[name] =  {
        //             get value() {
        //                 return this.variables[n].value[i]
        //             }
        //         }
        //     this.variables[name].name = name

        //     this.variables[name].value = _ge
        // }


        if (!this.variables[name])
            this.variables[name] = new PLCHandle()
        this.variables[name].name = name
        return this.variables[name]
    }


    async start() {

        const config = {
        iceServers: [
            { 
            "urls": "stun:stun.l.google.com:19302",
            }
        ]
        };

        this.socket = await io("http://192.168.30.153:1337");
        
        console.log("STARTTTTT ")
        
        this.socket.on("plc_read", (message) => {
            // console.log("read1", message)
            var vs = JSON.parse(message)
            vs.forEach(v => {
                let h = this.getHandle(v.name)
                h.plc = v.plc
                h.value = v.value
                this.change$.next(h)
            })
            // console.log("read2", this.variables)
        })

        this.socket.on("connect", () => {
            this.socket.emit("plc_subscriber",this.socket.id, "RULLIERA")
            this.socket.emit("plc_subscriber",this.socket.id, "ZONA3")
            this.socket.emit("plc_subscriber",this.socket.id, "ASPIRAZIONE")
        });

    }
}