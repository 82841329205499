import { Component, OnInit, Input, ElementRef, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Chain, Conveyor, Machine, Area, Action }  from 'src/models/map';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html'
})
export class MapComponent implements OnInit {
  
  @Input() lamps:Machine[]
  @Input() chains:Chain[]
  @Input() conveyors:Conveyor[]
  @Input() areas:Area[]
  @Input() actions:Action[]
  @Input() highlights:Machine[] = []
  @Input() isMultiSelect:boolean = false

  selections: Machine[] = [];
  selection: Machine;
  $selection:Subject<Machine> = new Subject()
  isAdding: any;

  constructor(
    private elRef:ElementRef
  ) { }

  ngOnInit() {
    
  }

  

  onTouchStart(e, m:Machine) {

    if (m.disabled)
      return
    
    if (!this.isMultiSelect) {
      this.select(m)
      return 
    } else {
      e.preventDefault()

      if (!this.isAdding) {
        this.isAdding = true
        if (this.selections.length == 1 && this.selections[0] == m) 
          this.selections = []
        else
          this.selections = [m]
      } else {
        this.selections = this.selections.concat(m)
      }
      
      if (this.selections.length == 1)
        this.select(this.selections[0])
      else 
        this.select(null)
    }
  }

  onTouchEnd(e, m:Machine) {
    e.preventDefault()
    if (!this.isMultiSelect) {
      return 
    } else {
      this.isAdding = false
      console.log("IS ADDING ebd", this.isAdding)
    }
    console.log("Touch end", m)
  }

  select(i:Machine) {
    
    if (this.selection == i)
      this.selection = null
    else {
      this.selection = i
    }

    console.log("SELE11", i)
    this.$selection.next(i)
  }
}
