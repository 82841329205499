import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestapiService } from 'src/app/services/restapi.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  @Input() public subtitle:string
  error;
  
  constructor(
    private route:ActivatedRoute,
    private api:RestapiService) {
    
    this.route.url.subscribe(e => {
      console.log(e.toString())
      if (e.toString().indexOf("alarms-aspirazione")) {
        this.subtitle = "SETTAGGI"
      } else if (e.toString().indexOf("alarms-aspirazione")) {
        this.subtitle = "SETTAGGI"
      } else if (e.toString().indexOf("aspirazione") > -1)
        this.subtitle = "SETTAGGI"
      else if (e.toString().indexOf("rulliera") > -1)
        this.subtitle = "SETTAGGI"
      else if (e.toString().indexOf("bricchettatrice"))
        this.subtitle = "SETTAGGI"
      else
        this.subtitle = ""
    });
  }


  ngOnInit() {

    this.api.error.subscribe(e => {
      if (e)
        this.error = e
    })
  }

}
