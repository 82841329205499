<nav class="navbar navbar-dark bg-dark navbar-expand">
  <a class="navbar-brand" href="#"><span class="text-muted">SUPERVISORE </span>
        <span *ngIf="subtitle" class="text-white"> 
            / {{subtitle}}
        </span>
    </a>
    <div class="collapse navbar-collapse">

        <button [routerLink]="['/aspirazione']"  routerLinkActive="text-primary" class="btn btn-lg ml-auto text-nowrap">Aspirazione</button>
        <button [routerLink]="['/run']"  routerLinkActive="text-primary" class="btn btn-lg text-nowrap">Zone 1-2</button>
        <button [routerLink]="['/outgo']"  routerLinkActive="text-primary" class="btn btn-lg mr-3 text-nowrap">Zona 3</button>

        <!-- <button [routerLink]="['/debug']" routerLinkActive="text-primary" class="btn"><i class="far fa-2x fa-search"></i></button> -->
        <button [routerLink]="['/settings']" routerLinkActive="text-primary" class="btn"><i class="far fa-2x fa-sliders-v-square"></i></button>
        
        <!-- <ul class="ml-3 navbar-nav">
            <li class="nav-item" >
                <a class="nav-link" [routerLink]="['/alarms-aspirazione']" routerLinkActive="text-primary">
                    <i class=" fal fa-2x fa-fan"></i>
                </a> 
            </li>
            <li>
                <a class="nav-link" [routerLink]="['/alarms-rulliera']" routerLinkActive="text-primary">
                    <i class=" fal fa-2x fa-conveyor-belt-alt "></i>
                </a> 
            </li>
        </ul> -->
    </div>
    <div class="error" *ngIf="error">{{error}}</div>

</nav>
