import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { PLCHandle, WSService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-push-button',
  templateUrl: './push-button.component.html',
  styleUrls: ['./push-button.component.scss']
})
export class PushButtonComponent implements OnInit {

  @Input() variable: PLCHandle

  @HostBinding('class') get class() {
    return this.variable?.value ? 'bg-success' : 'bg-muted'
  }

  @HostListener('mousedown', ['$event']) onMouseDown(e) {
    this.wss.socket.emit("plc_write2", [this.variable], [true]);
    
  }

  @HostListener('mouseup', ['$event']) onMouseUp(e) {
    this.wss.socket.emit("plc_write2", [this.variable], [false]);
  }


  constructor(
    private wss: WSService
  ) { }

  ngOnInit(): void {

  }




  

}
