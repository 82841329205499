<div class="d-flex flex-column p-5 align-items-center justify-content-center h-100">
    
    <i class="fa fa-2x fa-bell text-white "></i>
    
    <div class="d-flex flex-row my-4">
        <button  class="bg-white btn btn-lg mr-2" (touchstart)="onReset()">Reset</button>
        <button *ngIf="!ignore" class="btn btn-outline-primary ml-2 text-white ignore-d-none" (click)="ignore = true">Nascondi</button>
        <button *ngIf="ignore" class="btn btn-outline-primary ml-2 text-white ignore-d-none" (click)="ignore = false">Mostra</button>
    </div>

    <ng-content></ng-content>

    <!-- <ng-container *ngFor="let b of alarmsBitMap?.value; let i = index">
        <pre class="text-center text-white" *ngIf="alarmsBitMap.value[i] == true && alarmsBitMapLabel.length > i && alarmsBitMapLabel[i].length > 1">{{alarmsBitMapLabel[i]}}</pre>
    </ng-container> -->

    <pre>
        <ng-container *ngFor="let a of alarms">
            {{a.name}}
        </ng-container> 
    </pre> 
        <!-- <pre class="text-center text-white" *ngIf="alarmsBitMap.value[i] == true && alarmsBitMapLabel.length > i && alarmsBitMapLabel[i].length > 1">{{alarmsBitMapLabel[i]}}</pre>
-->

    
</div>
    
  


