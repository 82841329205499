import { Component, OnInit } from '@angular/core';
import { AbstractMachine } from '../abstract-component';

@Component({
  selector: 'app-chain',
  templateUrl: './chain.component.html'
})
export class ChainComponent extends AbstractMachine {


}
