import { OnInit, Input, Component, OnDestroy, HostBinding } from '@angular/core';
import { RestapiService, PLCSubscription } from 'src/app/services/restapi.service';
import { PLCHandle, WSService } from 'src/app/services/ws.service';
import { MapArea, Machine, PLCBooleanVar } from 'src/models/map';

@Component({ template: '' })
export class AbstractMachine implements OnInit, OnDestroy {

    @HostBinding('class.highlight') get h () { return this.highlight ? this.highlight.value : false } 
    
    @Input() model:Machine
    automatic?: PLCSubscription
    lamp?: PLCHandle
    highlight?: PLCHandle
    status?: PLCHandle
    statuses?: PLCHandle[] = []
    label?: PLCHandle
    alarms?: PLCHandle[] = []
    subscriptions?: PLCSubscription[] = []
    counterActual?: PLCHandle
    counterSet?: PLCHandle
    continuos?: PLCHandle

    constructor(
        protected api:RestapiService,
        protected ws:WSService
    ) { }
  
    ngOnInit() {
        if (this.model.lamp)
            this.lamp = this.ws.getHandle(this.model.lamp.name)

        if (this.model.status)
            this.status = this.ws.getHandle(this.model.status.name)

        if (this.model.statuses) {
            this.model.statuses.forEach(a => {
                
                this.statuses.push(this.ws.getHandle(a.name))
            })
        }

        if (this.model.highlight) {
            this.highlight = this.ws.getHandle(this.model.highlight.name)
        }

        if (this.model.alarms) {
            this.model.alarms.forEach(a => {
                this.alarms.push(this.ws.getHandle(a.name))
            })
        }
       
        if (this.model.label)
            this.label =this.ws.getHandle(this.model.label)

        if (this.model.counterActual)
            this.counterActual = this.ws.getHandle(this.model.counterActual.name)
        
        if (this.model.counterSet)
            this.counterSet = this.ws.getHandle(this.model.counterSet.name)

        // if (this.model.modes) {
        //     this.model.modes.forEach(s => {
        //         s = this.ws.getHandle(s.name)
        //         s.subscription = this.api.subscribeBoolean(s.variable)
        //         // this.subscriptions.push(s.subscription)
        //     })
        // }

        if (this.model.continuos) {
            this.continuos = this.ws.getHandle(this.model.continuos.name)
        }
    }

    getActiveModeIcon() {
        if (!this.model.modes) 
            return
        var icon 
        this.model.modes.forEach(s => {
            let x = this.ws.getHandle(s.variable.name)
            if (Boolean(x.value) == true) {
                // console.log("sss ", s.subscription.value, s.icon, Boolean(s.subscription.value))
                icon = s.icon
            }
        })
        return icon
    }

    ngOnDestroy() {
        // if (this.lamp)
        //     this.api.unsubscribe(this.lamp)
        this.subscriptions.forEach(s => {
            this.api.unsubscribe(s)
        })
    }
  
  }
  