import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { AbstractMachine } from '../abstract-component';
import { PLCSubscription } from 'src/app/services/restapi.service';
import { PLCHandle } from 'src/app/services/ws.service';

@Component({
  selector: 'app-conveyor',
  templateUrl: './conveyor.component.html',
  
})
export class ConveyorComponent extends AbstractMachine {

  
  @ViewChild('inputPcs') inputPcs : ElementRef
  continuos: PLCHandle

  tryFocusInput() {
    console.log("input", this.inputPcs)
    if (!this.inputPcs) return
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    // if (this.counterActual)
    //     this.api.unsubscribe(this.counterActual)
    // if (this.counterSet)
  }

  inc($event) {
    this.api.write(this.counterSet, Number(this.counterSet.value) + 1)
    $event.stopPropagation();
  }

  dec($event) {
    if (Number(this.counterSet.value) > 0)
      this.api.write(this.counterSet, Number(this.counterSet.value) - 1)
    $event.stopPropagation();
  }

  infinite($event) {
    $event.stopPropagation();
    this.api.write(this.counterSet, 0)
    this.api.write(this.continuos, !this.continuos.value)
  }

  updateCounterSet($event) {
    if ($event < 1)
      $event = 1
    this.api.write(this.counterSet, Number($event))
    console.log("update counter", $event)
  }

}
