
<div *ngIf="isLoading" class="modal  show d-flex align-items-center" style="background-color: rgba(0,0,0,0.5);" stabindex="-1">
    <div class="modal-dialog w-75">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Layout per scarico commessa</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="isLoading = false">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <ul class="list-group">
                <li class="list-group-item" *ngFor="let l of layouts | keyvalue" (click)="applyLayout(l.value)" >
                    {{l.key}}
                </li>
            </ul>
        </div>
        <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div> -->
        </div>
    </div>
</div>

<!-- --{{cmdNumBox.value}}-- -->

<div class="d-flex flex-column w-100" >
    <div class="gruppi d-flex w-100" style="margin-left: 12%;">
        <ng-container *ngFor="let g of groups;let i = index;">
            <div
                class="card"
                *ngIf="i == 0 || g.enabled || isConfig"
                [ngClass]="{'selected': activeGroup == g}"
                [ngStyle]="{'min-width': (g.size * 3.85) + '%'}" 
                (click)="onGroup(g);$event.stopPropagation()"
            >
                <div class="card-body" [ngClass]="{'bg-light': activeGroup == g}">
                    <b>{{g.from}}-{{g.to}}</b>
                    <br>
                    Da {{g.minLength/10 | number:'1.0-0':"it-IT"}} a {{g.maxLength / 10 | number:'1.0-0':"it-IT"}} cm
                    <br>
                    Centrato
                    {{g.quote/10 | number:'1.0-0'}}
                    <br>
                    <!-- Scarico a {{g.quote/10 | number:'1.0-0'}} -->
                </div>
            </div>
        </ng-container>
    </div> 

    <div *ngIf="isConfig" class="mt-3 d-flex justify-content-center">
        <button class="close" (click)="onClickClose()">
            <i class="fa fa-2x fa-close"></i>
        </button>
        
        <div class="card mr-3" style="width: 600px;" *ngIf="activeGroup">
            <div class="card-body d-flex flex-column">
                    
                <div class="d-flex align-items-center">
                    <label class="d-inline-block  mb-0">
                        Abilitato
                    </label>
                    <input type="checkbox" value="1" (click)="$event.stopPropagation()" class="ml-4" style="max-width: 100px"  (change)="update()" [(ngModel)]="activeGroup.enabled">
                    <button class="ml-auto mr-3 btn btn-primary text-white" (click)="dec(activeGroup)"><i class="fa fa-minus"></i></button>
                    <button class="btn btn-primary text-white" (click)="inc(activeGroup)"><i class="fa fa-plus"></i></button>
                </div>

                <div class="d-flex text-nowrap align-items-center">
                   
                </div>

                    <div class="d-flex  text-nowrap align-items-center mt-2">
                        <label class="d-inline-block  mb-0">
                            Lunghezza
                        </label>
                        <span class="ml-auto mr-2">Min</span>
                        <input type="number" readonly (click)="$event.stopPropagation()" class="mr-3 form-control" style="max-width: 100px"  (change)="update()" [(ngModel)]="activeGroup.minLength">
                        <span class="mr-2">Max</span>
                        <input type="number" readonly (click)="$event.stopPropagation()" class="form-control" style="max-width: 100px"  (change)="update()"  [(ngModel)]="activeGroup.maxLength">
                    </div>

                    <div class="d-flex justify-content-between text-nowrap align-items-center mt-2">
                        <label class="d-inline-block  mb-0">
                            Larghezza
                        </label>
                        <span class="ml-auto mr-2">Min</span>
                        <input type="number" readonly (click)="$event.stopPropagation()" class="mr-3 form-control" style="max-width: 100px" [(ngModel)]="activeGroup.minWidth">
                        <span class="mr-2">Max</span>
                        <input type="number" readonly (click)="$event.stopPropagation()" class="form-control" style="max-width: 100px" [(ngModel)]="activeGroup.maxWidth">
                    </div>
                    <div class="d-flex justify-content-between text-nowrap align-items-center mt-2">
                        <label class="d-inline-block  mb-0">
                            Altezza
                        </label>
                        <span class="ml-auto mr-2">Min</span>
                        <input type="number" readonly (click)="$event.stopPropagation()" class="mr-3 form-control" style="max-width: 100px" [(ngModel)]="activeGroup.minHeight">
                        <span class="mr-2">Max</span>
                        <input  type="number" readonly (click)="$event.stopPropagation()" class="form-control" style="max-width: 100px" [(ngModel)]="activeGroup.maxHeight">
                    </div>
            </div>
        </div>

        <div class="card" style="width: 300px;" *ngIf="activeGroup">
            <div class="card-body">
                    <div class="d-flex justify-content-between text-nowrap align-items-center">
                        <label class="d-inline-block  mb-0">
                            Allineamento
                        </label>
                        <select class="form-control" style="max-width: 100px" [(ngModel)]="activeGroup.align" (change)="update()">
                            <option value="sx">Sinistra</option>
                            <option value="cc">Centro</option>
                            <option value="dx">Destra</option>
                        </select>
                    </div>
                    
                    <!-- <div class="d-flex justify-content-between text-nowrap align-items-center mt-2">
                        <label class="d-inline-block  mb-0">
                            Offset testa
                        </label>
                        <input type="number" class="form-control" style="max-width: 100px" [(ngModel)]="activeGroup.offsetHead">
                    </div>
                    <div class="d-flex justify-content-between text-nowrap align-items-center mt-2">
                        <label class="d-inline-block  mb-0">
                            Offset coda
                        </label>
                        <input class="form-control" style="max-width: 100px" [(ngModel)]="activeGroup.offsetTail">
                    </div> -->

                    <div class="d-flex justify-content-between text-nowrap align-items-center mt-2">
                        <label class="d-inline-block  mb-0">
                            Passo catena
                        </label>
                        <input  type="number" class="form-control" style="max-width: 100px" [(ngModel)]="activeGroup.step">
                    </div>
            </div>
        </div>

        <div class="d-flex align-items-center flex-column ml-3 ">
            <button class="d-block btn btn-lg btn-dark mb-2" (click)="add()"><i class="mr-2 fa fa-plus text-white"></i> Gruppo</button>
            <button class="d-block btn btn-lg btn-dark mb-2" (click)="remove(activeGroup)" *ngIf="activeGroup"><i class="mr-2 fa fa-trash text-white" ></i> Gruppo</button>
            <button class="d-block btn btn-lg btn-success mb-4" (click)="apply()" *ngIf="activeGroup"><i class="mr-2 fa fa-check text-white" ></i> Applica</button>
        </div>
    </div>
</div>

<div class="app-context-menu p-2" *ngIf="activeGroup && !isConfig && hasFocus">
    <div class="ml-3  d-flex align-items-center">
        <span class="lead  text-white"><b>Gruppo</b></span>
      </div>

      <!-- <div class="ml-3 ">
        <button (click)="onClickLoad()">Carica</button>
      </div> -->

      <div class="ml-3 mr-auto" >
        <button 
            *ngIf="!ws.variables['EXCHANGE_HMI.Start_Rulliera_Auto@ZONA3'].value"
            (click)="onClickConfig()"
        >Configura</button>
      </div>

      <!-- <div class="ml-3">
        <button *ngFor="let b of machine.buttons" (click)="click(b)" [ngClass]="{'bg-primary': b.subscription?.value == true}" [title]="b.name">{{b.name}}</button>
      </div>
       -->
      <div class="ml-3">
        <button *ngFor="let s of machine?.switches" (click)="switch(s)"  [title]="s.name" [ngClass]="{'bg-primary': s.value} ">{{s.name}}</button>
      </div>
      
      <div class="ml-3">
        <button 
            *ngIf="ws.variables['EXCHANGE_HMI.Start_Rulliera_Auto@ZONA3'].value"
            (click)="test()" [ngClass]="{'bg-primary': cmdSemiAuto.value} ">Sblocco innesti</button>
      </div>

      <div class="ml-3">
        <button 
            *ngIf="!ws.variables['EXCHANGE_HMI.Start_Rulliera_Auto@ZONA3'].value"
            (click)="onCmdSemiAuto($event)" [ngClass]="{'bg-primary': cmdSemiAuto.value} "><i class="fa fa-arrow-from-top"></i></button>
      </div>
      
      <div class="ml-3">
        <button (touchstart)="scaricaSoloCatenaria($event,1,true)" (touchend)="scaricaSoloCatenaria($event,0,false)"><i class="fa fa-arrow-down"></i></button>
        <button (touchstart)="scaricaSoloCatenaria($event,2,true)" (touchend)="scaricaSoloCatenaria($event,0,false)"><i class="fa fa-arrow-up"></i></button>
      </div>


      <div class="ml-4">
        <!-- {{this.ws.variables.length}}-{{this.ws.variables.Automatico}} -->
        <ng-container *ngFor="let m of machine.buttons">
            <button (click)="select(m.variable)" [ngClass]="{'bg-primary': m.variable.value == true}" [title]="m.name"> 
                <span>{{m.name}} </span>
            </button>
        </ng-container>
      </div>

</div>

