import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input, OnDestroy } from '@angular/core';
import { MapComponent } from '../map/map.component';
import { Machine } from 'src/models/map';
import { RestapiService } from 'src/app/services/restapi.service';
import { WSService } from 'src/app/services/ws.service';


class PushButtonWrapper {
  constructor(icon, buttons) {
    this.icon = icon
    this.buttons = buttons
  }
  buttons:[]
  icon:string
}

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
})
export class ContextMenuComponent implements OnInit, OnDestroy {


  constructor(
    private apiservice:RestapiService,
    private ws:WSService
  ) { }


  // @Input() machine:Machine
  _machines = []
  _machine:Machine

  get machine() {
    return this._machine
  }
  @Input() set machine(value: Machine) {
    this.ngOnDestroy()

    this._machine = value
    if (!value)
      return 

    if (value.switches)
    value.switches.forEach(s => {
      s.variable = this.ws.getHandle(s.variable.name)
      // s.subscription = this.apiservice.subscribeBoolean(s.variable)
      // this.subscriptions.push(s.subscription)
    })

    if (value.modes)
      value.modes.forEach(b => {

        b.variable = this.ws.getHandle(b.variable.name)
        console.log("VARR", b.variable)
        // b.subscription = this.apiservice.subscribeBoolean(b.variable)
        // this.subscriptions.push(b.subscription)
      })

    if (value.buttons)
      value.buttons.forEach(b => {
      
        // if (b.variable.plc == "ZONA3") {
          if (!this.ws.variables[b.variable.name])
              this.ws.variables[b.variable.name] = {}
          b.variable = this.ws.variables[b.variable.name]
        // } 

        if (b.feedback) {
          // b.subscription = this.apiservice.subscribeBoolean(b.feedback)
          // this.subscriptions.push(b.subscription)
        }
      })
  }

  @Input() set machines(value: Machine[]) {
    this.ngOnDestroy()
    var pbs = []
    this.pushbuttons = []
    
    if (!value)
      return

    for (var x =0; x < (value as []).length; x++) {
      var m = value[x]
      if (m.pushbuttons)
          pbs = pbs.concat(m.pushbuttons)
    }

    var gs = this.groupBy(pbs,"icon")

    Object.keys(gs).forEach(key => {
      // if its for all
      if (gs[key].length == value.length) {
        this.pushbuttons.push(new PushButtonWrapper(key, gs[key]))
      }
    })
  }

  private subscriptions = []
  private dinamicSubscriptions = []

  pushbuttons:PushButtonWrapper[] = []
  
  

  ngOnInit() {
    // subscribe
    if (!this.machine) 
      return 


    
  }

  groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      this.apiservice.unsubscribe(s)
    })
  }

  switch(s) {
    console.log("SWITCH ", s.variable.value)
    this.apiservice.writeBoolean(s.variable, !s.variable.value)
  }

  click(s) {

    console.log("SWITCH ", s.variable.value)

    this.apiservice.writeBoolean(s.variable, true)
    setTimeout(() => {
      this.apiservice.writeBoolean(s.variable, false)
    }, 300);
  }


  press(e,s) {
    e.preventDefault()
    if (s.variable) {
      this.apiservice.writeBoolean(s.variable, true)
    } else {
      // it's a wrapper
      console.log("++" , s)
      s.buttons.forEach(b => {
        this.apiservice.writeBoolean(b.variable, true)
      })
    }
  }

  release(e,s) {
    e.preventDefault()
    if (s.variable) {
      this.apiservice.writeBoolean(s.variable, false)
    } else {
      // it's a wrapper
      console.log("++" , s)
      s.buttons.forEach(b => {
        this.apiservice.writeBoolean(b.variable, false)
      })
    }
  }


  select(variable) {
    console.log("SWITCH variable", variable)
    this.machine.modes.forEach(m => {
      console.log("SWITCH mode", m)
      if (m.variable.name != variable.name) {
        this.apiservice.write(m.variable, false)  
        console.log("SWITCH sele1 false", m.variable)

      }
    })

    console.log("SWITCH sele1 true", variable)
    this.apiservice.write(variable, true)      
  }

}

