import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import localeIt from '@angular/common/locales/it';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PartialsModule } from './views/partials/partials.module';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { ToggleVariableDirective } from './toggle-variable.directive';

registerLocaleData(localeIt, 'it-IT');

@NgModule({
  declarations: [
    AppComponent,
    ToggleVariableDirective,
  ],
  imports: [
    BrowserModule,
    PartialsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
