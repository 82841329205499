import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { RestapiService } from 'src/app/services/restapi.service';

@Component({
  selector: 'app-alarm-button',
  templateUrl: './alarm-button.component.html'
})
export class AlarmButtonComponent implements OnInit {
  alarms

  counter:Number = 1
  constructor(
    private api: RestapiService
  ) { 
    // this.alarms = als.alarms 
  }
 

  ngOnInit() {
    return
    this.alarms.forEach(a => {
      let re = /\(/gi;
      a.message = a.message.replace(re, "<br>");
      let re2 = /\)/gi;
      a.message = a.message.replace(re2, "");

      // a.subscription = this.api.subscribeBoolean({plc: "ASPIRAZIONE", address: "MAIN." + a.address})
    });
  }

  

}
