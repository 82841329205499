import { Component, OnInit, Input } from '@angular/core';
import { MotorSetting } from 'src/models/map';
import { RestapiService, PLCSubscription } from 'src/app/services/restapi.service';
import { PLCHandle } from 'src/app/services/ws.service';

@Component({
  selector: 'app-motor-control',
  templateUrl: './motor-control.component.html'
})
export class MotorControlComponent implements OnInit {

  @Input() motor:MotorSetting
  run: PLCHandle

  constructor(
    private api: RestapiService
  ) { }

  ngOnInit() {
    // this.run = this.api.subscribeBoolean(this.motor.feedbackRunOn)
  }

  on() {
    console.log("ON")
    this.api.write(this.motor.start, true)
    setTimeout(x => {
      this.api.write(this.motor.start, false)
    },250)
  }

  off() {
    this.api.write(this.motor.stop, true)
    setTimeout(x => {
      this.api.write(this.motor.stop, false)
    },250)
  }

}
