import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { PLCVar, PLCBooleanVar, PLCStringVar } from 'src/models/map';
import { BehaviorSubject, Subject } from 'rxjs';

import { timeout, withLatestFrom } from 'rxjs/operators';
import { PLCHandle, WSService } from './ws.service';

export class PLCSubscription {
  plc: string
  address: string
  clazz: string 
  value: any
  length?: number 
}

@Injectable({
  providedIn: 'root'
})
export class RestapiService {

  private host = "http://192.168.30.180:1880"
  private READ_ENDPOINT = this.host + "/service/call/app.plc.microservices.PlcRead";
  private WRITE_ENDPOINT = this.host + "/service/call/app.plc.microservices.PlcWrite";
  private SQL_ENDPOINT = this.host + "/service/call/app.database.ExecuteOnDB";

  private subscriptions : PLCSubscription[] = []
  busy: boolean;
  error = new BehaviorSubject(null); // 0 is the initial value
  read:Subject<null> = new Subject<null>();
  invalidateNextRead: boolean;


  constructor(
    private http: HttpClient,
    private wss: WSService
  ) { 
    setInterval(() => {
      this.update()
    }, 500)
  }

  query(q:string):Promise<any> {
    return this.http.post(this.SQL_ENDPOINT, JSON.stringify({"sql":q})).toPromise()
  }

  groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);


  
  async update() {
    return
    if (this.busy)
      return

    var g = this.groupBy(this.subscriptions, i=> i.plc)
    // group by PLC
    var promises = []

    this.busy = true
    
    Object.keys(g).forEach(async k => {
      ((k) => {
        console.log("Kk, k", k)
        
        promises.push(new Promise( (res)=> {
          let body = {
            "plc" : k,
            "vars": g[k]
          }
           this.http.post(this.READ_ENDPOINT, JSON.stringify(body)).pipe(timeout(12000)).toPromise().then( (r:any) => {
            
            this.error.next(null) 
            r.vars.forEach((x,i) => {
              g[k][i].value = r.vars[i].value
            })
            res(null)
          }).catch(x => {
            if (x.message)
              this.error.next(x.message) 
            else if (x.error)
              this.error.next(JSON.stringify(x.error)) 
            console.log("SBAGLIOOOOOOOO",x)
            res(null)
          })
          
        }))  
      })(k)
      
      // build body for request

    })

    Promise.all(promises).then(x => {
      this.busy = false
      if (this.invalidateNextRead) {
        this.invalidateNextRead = false
        return
      }
      this.read.next()
    })
  }

  async write(v:PLCHandle, value) {
    console.log("write",v )
    this.wss.socket.emit("plc_write2", [v], [value]);
    return
  }

  async writeString(v:PLCHandle, value) {
    this.write(v,value)
  }

  async writeBoolean(v:PLCHandle, value) {
    this.write(v,value)
  }

  unsubscribeAll() {
    this.subscriptions = []
  } 

  unsubscribe(s:PLCSubscription ) {
    this.subscriptions.splice(this.subscriptions.indexOf(s),1)
  }

  // subscribe(v:PLCVar) {
  //   let s:PLCSubscription = new PLCSubscription()
  //   s.clazz = v.clazz
  //   s.plc = v.plc
  //   s.address = v.address
  //   this.subscriptions.push(s)
  //   return s
  // }

  // subscribeDouble(v:PLCVar) {
  //   let s:PLCSubscription = new PLCSubscription()
  //   s.clazz = "java.lang.Double"
  //   s.plc = v.plc
  //   s.address = v.address
  //   this.subscriptions.push(s)
  //   return s
  // }


  // subscribeFloat(v:PLCVar) {
  //   let s:PLCSubscription = new PLCSubscription()
  //   s.clazz = "java.lang.Float"
  //   s.plc = v.plc
  //   s.address = v.address
  //   this.subscriptions.push(s)
  //   return s
  // }

  // subscribeShort(v:PLCVar) {
  //   let s:PLCSubscription = new PLCSubscription()
  //   s.clazz = "java.lang.Short"
  //   s.plc = v.plc
  //   s.address = v.address
  //   this.subscriptions.push(s)
    
  //   return s
  // }


  // subscribeBoolean(v:PLCVar):PLCSubscription {
  //   let s:PLCSubscription = new PLCSubscription()
  //   s.clazz = "java.lang.Boolean"
  //   s.plc = v.plc
  //   s.address = v.address
  //   this.subscriptions.push(s)
  //   return s
  // }


  // subscribeInteger(v:PLCVar):PLCSubscription {
  //   let s:PLCSubscription = new PLCSubscription()
  //   s.clazz = "java.lang.Integer"
  //   s.plc = v.plc
  //   s.address = v.address
  //   this.subscriptions.push(s)
  //   return s
  // }

  // subscribeString(v:PLCVar):PLCSubscription {
  
  //   let s:PLCSubscription= new PLCSubscription()
  //   s.clazz = "java.lang.String"
  //   s.plc = v.plc
  //   s.address = v.address
  //   s.length = v.length
    
  //   this.subscriptions.push(s)
  //   return s
  // }


}


