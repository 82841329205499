import { Component, OnInit } from '@angular/core';
import { RestapiService, PLCSubscription } from 'src/app/services/restapi.service';

@Component({
  selector: 'app-screensaver',
  templateUrl: './screensaver.component.html',
  host: {
    '[class.visible]': 'visible' 
  }
})
export class ScreensaverComponent implements OnInit {

  public visible = true
  timeout
  number: PLCSubscription;
  actual: PLCSubscription;
  setpoint: PLCSubscription;
  a1: PLCSubscription;
  a2: PLCSubscription;
  
  constructor(
   private  api: RestapiService
   
  ) { 
      document.addEventListener("mousedown", e => {
        this.reset()
      })
  }

  ngOnInit() {
    // this.actual = this.api.subscribeShort({"plc": "RULLIERA","address" : "RULLI_CENTRO_TAGLI.Act_Pezzi_Valmec"})
    // this.setpoint = this.api.subscribeShort({"plc": "RULLIERA","address" : ".Set_Pezzi_Valmec"})
    // this.a1 = this.api.subscribeBoolean({"plc": "RULLIERA","address" : "RULLI_CENTRO_TAGLI.M_Auto_Cycle_Run_Zona_1"})
    // this.a2 = this.api.subscribeBoolean({"plc": "RULLIERA","address" : "RULLI_CENTRO_TAGLI.M_Auto_Cycle_Run_Zona_2"})
    // this.reset()
    // this.setpoint = this.api.subscribeBoolean({"plc": "RULLIERA","address" : ".Set_Pezzi_Valmec"})
    // "plc" : "RULLIERA",
    //             "address": "RULLI_CENTRO_TAGLI.M_Auto_Cycle_Run_Zona_1"
  }


  reset() {
    this.visible = false
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }

    this.timeout = setTimeout(x => {
      this.visible = true
    },60 *1000)
  }


  public onClick() {
    this.reset()
  }

}
