import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { PLCHandle, WSService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-button-switch',
  templateUrl: './button-switch.component.html',
  styleUrls: ['./button-switch.component.scss'],
  // host:     {'[class.bg-danger]':'variable.value'}
})
export class ButtonSwitchComponent implements OnInit {

  @Input() variable: PLCHandle
  
  @HostBinding('class') get class() {
    return this.variable?.value ? 'bg-success' : 'bg-muted'
  }
  
  @HostListener('click', ['$event']) onClick(e) {
    console.log("PLC WRITE ", this.variable)
    this.wss.socket.emit("plc_write2", [this.variable], [!this.variable.value])
  }
  
  constructor(
    private wss: WSService
  ) { }

  
  ngOnInit(): void {
  }

}
