import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PLCSubscription, RestapiService } from 'src/app/services/restapi.service';
import { PLCHandle, WSService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-plc-input-boolean',
  templateUrl: './plc-input-boolean.component.html'
})
export class PlcInputBooleanComponent implements OnInit, OnDestroy {

  
  @Input() label:string
  @Input() handleName:string
  @Input() readonly: boolean = false
  subscription: PLCSubscription
  h: PLCHandle;

  constructor(
    private apiservice:RestapiService,
    private wss:WSService
  ) { }

  ngOnInit() {
    // this.subscription = this.subscribe()
    this.h = this.wss.getHandle(this.handleName)
  }

  ngOnDestroy() {
    this.apiservice.unsubscribe(this.subscription)
  }

  protected subscribe() {
    // return this.apiservice.subscribeBoolean({plc:this.plc, address: this.address})
  }

  changed(value) {
    console.log("changed boolean", value)
    this.wss.socket.emit("plc_write2", [this.h], [value]);
    // this.apiservice.writeBoolean({plc:this.plc, address: this.address}, value)
  }


}
