import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RestapiService, PLCSubscription } from 'src/app/services/restapi.service';

@Component({
  selector: 'app-plc-input-double',
  templateUrl: './plc-input-double.component.html'
})
export class PlcInputDoubleComponent implements OnInit, OnDestroy {

  @Input() label:string
  @Input() plc:string
  @Input() address:string
  @Input() readonly: boolean = false
  
  value:string
  subscription: PLCSubscription
  

  constructor(
    private apiservice:RestapiService
  ) { }

  ngOnInit() {
    // this.subscription = this.subscribe()
  }

  ngOnDestroy() {
    this.apiservice.unsubscribe(this.subscription)
  }

  protected subscribe() {
    // return this.apiservice.subscribeDouble({plc:this.plc, address: this.address})
  }

  change($e) {
    // this.apiservice.write({plc:this.plc, address: this.address, clazz: this.getVarType()}, $e.target.value)
  }

  protected getVarType():string {
    return "java.lang.Double"
  }
}
