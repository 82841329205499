import { ThrowStmt } from '@angular/compiler';
import { AfterViewInit, ApplicationRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PLCSubscription, RestapiService } from 'src/app/services/restapi.service';
import { PLCHandle, WSService } from 'src/app/services/ws.service';
import { Machine, PLCBooleanVar, PLCStringVar, PLCVar } from 'src/models/map';

import  *  as  c  from  '../../../../models/map-outgo.json';

class Group  extends Machine {
  number: number
  from:number
  to:number
  minWidth:number  = 0
  maxWidth:number  = 500
  maxLength:number  = 500
  minLength:number =0
  maxHeight:number = 500
  minHeight:number = 0
  size:number = 1
  align:string = "cc"
  step:number = 0
  offsetTail:number = 0
  offsetHead:number = 0
  num: any;
  enabled: boolean;
  even:boolean = false;
  quote: number;
}

@Component({
  selector: 'app-config-outgo',
  templateUrl: './config-outgo.component.html',
  styleUrls: ['./config-outgo.component.scss'],
})
export class ConfigOutgoComponent implements OnInit, OnDestroy {

  activeGroup:Group = null

  @Input() chains
  @Output() onDone = new EventEmitter<null>()
  @HostBinding('class.isConfig') isConfig = false;

  layouts = {
    "10289":  ["1|1|947|1487|0|500|0|500|cc|1|2|101|-100|260|423.5|1", "2|1|1487|8027|0|500|0|500|cc|3|10|101|-100|260|5608.5|7", "3|1|1487|10234|0|500|0|500|cc|11|20|101|-100|260|15432|9"],
    "10231":  ["1|1|947|1487|0|500|0|500|cc|1|2|101|-100|260|423.5|1", "2|1|1487|8027|0|500|0|500|cc|3|10|101|-100|260|5608.5|7", "3|1|1487|10234|0|500|0|500|cc|11|20|101|-100|260|15432|9"],
    "10235":  ["1|1|947|1487|0|500|0|500|cc|1|2|101|-100|260|423.5|1", "2|1|1487|8027|0|500|0|500|cc|3|10|101|-100|260|5608.5|7", "3|1|1487|10234|0|500|0|500|cc|11|20|101|-100|260|15432|9"]
  }

  groups = [
    new Group(),
    new Group(),
    new Group(),
    // new Group()
  ]

  slot:any[] = []
  cmdSemiAuto: PLCHandle;
  cmdNumBox: PLCHandle;
  cmdNumBoxSub: PLCSubscription;
  subscriptions: any;
  hasFocus: boolean = false;
  cmdScaricaCatenaria: PLCHandle;
  direzioneScarica: PLCHandle;
  machine: any;
  c1: number;
  c2: number;
  isLoading: boolean;

  constructor(
    private applicationRef:ApplicationRef,
    private api: RestapiService,
    public ws: WSService,
  ) { }
  
  ngOnInit() {

    this.machine = c.areas[0] as any

    if (this.machine.buttons)

    for (var x = 0; x < this.machine.buttons.length;x ++) {
      var b = this.machine.buttons[x]


      if (!this.ws.variables[b.name])
        this.ws.variables[b.variable.name] = JSON.parse(JSON.stringify(b.variable))

      b.variable = this.ws.variables[b.variable.name]
    }
    
    // this.machine.buttons.forEach(b => {
    //   // b.variable.subscription = this.api.subscribeBoolean(b.variable)
    //   // if (b.feedback) {
    //   //   b.subscription = this.api.subscribeBoolean(b.feedback)
    //   // }
    // })
   

    this.direzioneScarica = this.ws.getHandle("Rulliera.Direzione_Scarica_SoloCatenaria_Imp@ZONA3")
    this.cmdScaricaCatenaria = this.ws.getHandle("Rulliera.CMD_Scarica_SoloCatenaria_Imp@ZONA3")
    this.cmdNumBox = this.ws.getHandle("Rulliera.CMD_Scarica_SoloCatenaria_NBox@ZONA3")

    this.cmdSemiAuto = this.ws.getHandle("Rulliera.CMD_Scarica_Rulliera_Passo_Ciclo_SemiAuto@ZONA3")
    

    this.slot[0] = this.ws.getHandle("GVL_Persistent_Var.sSrc_1@ZONA3")
    this.slot[1] = this.ws.getHandle("GVL_Persistent_Var.sSrc_2@ZONA3")
    this.slot[2] = this.ws.getHandle("GVL_Persistent_Var.sSrc_3@ZONA3")
    // this.slot[3] = this.ws.getHandle("GVL_Persistent_Var__sSrc_4")

    this.ws.socket.emit("plc_subscriber",this.ws.socket.id, "ZONA3")


    this.ws.change$.subscribe(h => {
      if (!h.name.endsWith("ZONA3"))
        return


     console.log("WS CHANGE ", h)

      if (h == this.cmdNumBox) {
        this.activeGroup = this.groups[this.cmdNumBox.value -1]
      }

      if (this.isConfig)
      return 

      if (h == this.slot[0])
        this.decode(this.slot[0])
      else if (h == this.slot[1])        
        this.decode(this.slot[1])
      else if (h == this.slot[2])
        this.decode(this.slot[2])
      // else if (h == this.slot[3])
      //   this.decode(this.slot[3])
      
      
      this.update()
    })
  }
  

  ngOnDestroy(): void {
    this.api.unsubscribeAll()
  }

  encode(g: Group): string {
    return  [this.groups.indexOf(g) +1 ,g.enabled ? 1 : 0,g.minLength,g.maxLength,g.minHeight,g.maxHeight, g.minWidth, g.maxWidth,g.align,g.from,g.to,g.step,g.offsetTail,g.offsetHead,g.quote,g.size].join("|")
  }
  
  decode(sub: PLCSubscription|PLCHandle) {
    if (!sub.value) return

    console.log("decodin", sub.value)

    var [num, enabled, lmin,lmax,amin,amax,spessmin,spessmax,align,first,last,passo,coda,testa,quote,size] = sub.value.split("|")
    
    console.log("DECO GROUP ",  Number.isNaN(Number(num)))
    
    
    if (  Number.isNaN(Number(num))) return



    var g = this.groups[Number(num) - 1]
    g.enabled = (enabled && enabled == "1" ) ? true : false
    
    g.num = Number(num) - 1
    g.minLength = Number(lmin)
    g.maxLength = Number(lmax)
    g.minWidth = Number(spessmin)
    g.maxWidth = Number(spessmax)
    g.minHeight = Number(amin)
    g.maxHeight = Number(amax)
    g.align = align
    g.from = Number(first )
    g.to = Number(last)
    // if (g.to == 0)
    //   g.to == g.from + 1
    g.step = Number(passo)
    g.offsetHead = Number(testa)
    g.offsetTail = Number(coda)
    g.number = Number(num)
    g.quote = Number(quote)
    g.size =  Number(size) 
  }

  add() {
    // if (this.slot.length >= 5) 
    //   return
    
    var n = new Group()
    if (this.activeGroup)
      this.groups.splice(this.groups.indexOf(this.activeGroup)+1,0,n)
    else 
      this.groups.push(n)
    
    this.activeGroup = n
    this.update()
  }

  remove(g:Group) {
    var x = this.groups.indexOf(this.activeGroup)
    this.groups.splice(x,1)

    if (x > 0)
      this.activeGroup = this.groups[x-1]
    else
      this.activeGroup = null
    
    this.update()
  }

  onClickClose() {
    this.onDone.emit()
  }

  onGroup(g:Group) {
    this.hasFocus = true
    this.api.write(this.cmdNumBox,this.groups.indexOf(g) + 1)
    console.log("dd", this.activeGroup)
  }

  getMaxLenght(g:Group) {
    return g.size  * 1099
  }

  onClickConfig() {
    this.isConfig = !this.isConfig
  }

  onClickLoad() {
    this.isLoading = !this.isLoading
  }
  




  apply() {
    // var g = this.groups[0]
    this.api.writeString(this.slot[0], this.encode(this.groups[0]))
    this.api.writeString(this.slot[1], this.encode(this.groups[1]))
    this.api.writeString(this.slot[2], this.encode(this.groups[2]))
    // this.api.writeString(this.slot[3], this.encode(this.groups[3]))

    this.onClickClose()
  }


  async applyLayout(l) {
    await this.api.writeString(this.slot[0], l[0])
    await  this.api.writeString(this.slot[1], l[1])
    await  this.api.writeString(this.slot[2], l[2])
    // this.api.writeString(this.slot[3], this.encode(this.groups[3]))
    this.isLoading = false
  }
  
  update() {
    var lastChainIndex = 1
    // calc min - max - from - to from just size and index 
    
    this.groups.forEach(g => {
      
      g.from = lastChainIndex 
      g.maxLength = 0

      console.log("--", g.from, g.to,  g.size)
      if (!g.from ||  !g.to || !g.size) {
        return
      }

      if (lastChainIndex + g.size > this.chains.length)
        return
      
      if (!this.chains[lastChainIndex + g.size])
        return
        
      g.maxLength = this.chains[lastChainIndex + g.size].gap - this.chains[lastChainIndex].gapl
      lastChainIndex = lastChainIndex + g.size
      g.to = lastChainIndex 
      lastChainIndex = lastChainIndex + 1
      
      console.log("GFRON ", g.from, g.to)

    })


    // calc minLenght

    // if (g.align == "cx") {
      this.groups.forEach(g => {
        g.align = "cc"
        try {
        var even = (g.size -1) % 2 == 0
        g.even = even
  
        if (even) {
          var c1 = (Number(g.from - 1)  + Math.floor(g.size / 2)) + 1
          var c2 = Number(g.from - 1)  + Math.floor(g.size / 2)

          console.log("EVEN ", c1, c2)
          g.even = true
          g.minLength = this.chains[(Number(g.from - 1)  + Math.floor(g.size / 2)) + 1].gap - this.chains[Number(g.from) -1  + Math.floor(g.size / 2)].gapl
        } else {
          g.even = false
          g.minLength = this.chains[Number(g.from- 1)  + Math.floor(g.size / 2) + 1].gap - this.chains[Number(g.from) -1  + Math.floor(g.size / 2) -1].gapl
        }
        } catch(e) {
          console.log(e)
          g.disabled = true
        }
      })
    // } else if (g.align == "sx") {

    // }

    // calc quote
    var quote = 0
    
    this.groups.forEach(g => {
      if (g.enabled) {
        console.log("align ", g.align)
        if (g.to == 0) 
          g.to = g.from + 1
        // if (g.align == "cc") {
          g.quote = (this.chains[(Number(g.to)) -1].gap - ((this.chains[(Number(g.to)) -1].gap - this.chains[(Number(g.from)) -1].gapl) / 2))
        // } else if (g.align == "sx") {
        //   g.quote = this.chains[(Number(g.from)) -1].gapl
        // }
      }
    }) 
    

    var g = this.activeGroup
    if (!g || !g.from || !g.to)
      return
    this.chains.filter((c,i) => {
      c.highlighted = false
      return (i+1) >= g.from && (i+1) <= g.to
    }).forEach(c => {
      c.highlighted = true
    })

    
    console.log(this.encode(this.groups[0]))
    console.log(this.encode(this.groups[1]))
    console.log(this.encode(this.groups[2]))
  }


  onCmdSemiAuto($event) {
    this.api.write(this.cmdSemiAuto,!this.cmdSemiAuto.value)
  }

  scaricaSoloCatenaria($event,direction, value ) {
    $event.preventDefault()
    this.api.write(this.direzioneScarica, direction) 
    this.api.write(this.cmdScaricaCatenaria, value)      
  }


  async test() {
    
    for (var loop = 0 ; loop < 10; loop++) {
      
      for (var x = 0; x < 23; x++) {
        let h = this.ws.getHandle("RULLIERA.Comandi_Manuali.Seleziona_CatenaLunga_Per_Cmd_Jog[" + x + "]@ZONA3")
        this.api.write(h, true)
        await this.delay(100)
      }
  
      for (var x = 0; x < 23; x++) {
        let h = this.ws.getHandle("RULLIERA.Comandi_Manuali.Seleziona_CatenaLunga_Per_Cmd_Jog[" + x + "]@ZONA3")
        this.api.write(h, false)
        await this.delay(100)
      }
    }
    
    
    
  }

  private delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  } 

  switch(s) {
    // this.cmdSemiAuto.value = !s.subscription.value
    // console.log("ss", this.semiAutoSub.value)
    // this.api.writeBoolean(this.cmdSemiAuto, !this.cmdSemiAutoSub.value)
  }

  select(variable) {
    // this.machine.modes.forEach(m => {

    console.log("w---", variable.value)
    var val 

    if (variable.value == undefined) {
      val = false
    } else if (variable.value == false) {
      val = true
    } else {
      val = false
    }
    console.log("m ", variable,val)
    
    
    this.api.write(variable, val)      
    // })
    // this.api.write(variable, true)      
  }

  inc(g:Group) {
    
    g.size = g.size + 1
    this.update()
  }

  dec(g:Group) {
    if (g.size < 2)
      return
    g.size = g.size - 1
    this.update()
  }



}

