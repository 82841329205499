<div class="text-dark p-2 d-flex align-items-center" >
    <div *ngIf="lamp" class="lamp" [ngClass]="{'bg-success': lamp.value, 'bg-danger': !lamp.value, 'left' : model.lamp.align && model.lamp.align == 'left'}">
    </div>    
    &nbsp;{{model.name}}&nbsp;
    <!-- <div *ngIf="statuses.length > 0 " class="status text-dark">
        / <span *ngFor="let s of statuses"> {{s.value}} </span>
    </div> -->
</div>


<div class="d-flex flex-row flex-wrap text-center justify-content-center ">
    <div  *ngIf="(counterActual )  && (!continuos || !continuos.value ) " class="label">
        {{counterActual.value | number : '1.0-0': "it-IT"}} <span *ngIf="model.counterActual.um">{{model.counterActual.um}}</span>
    </div>
    
    <div *ngIf="(counterSet && counterSet.value) && (!continuos || !continuos.value )" class="label">
        &nbsp;/ {{counterSet.value}} 
    </div>

    <div *ngIf="continuos && continuos.value" class="label">
        &#8734;
    </div>

</div>

<div *ngIf="label" class="label">
    <b>{{label.value}}</b>
</div>

