import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RestapiService, PLCSubscription } from 'src/app/services/restapi.service';

@Component({
  selector: 'app-plc-input',
  templateUrl: './plc-input.component.html'
})
export class PlcInputComponent implements OnInit, OnDestroy {

  @Input() label:string
  @Input() plc:string
  @Input() address:string
  @Input() readonly: boolean = false

  value:string
  subscription: PLCSubscription
  

  constructor(
    private apiservice:RestapiService
  ) { }

  ngOnInit() {
    // this.subscription = this.subscribe()
  }

  ngOnDestroy() {
    this.apiservice.unsubscribe(this.subscription)
  }

  protected subscribe() {
    // return this.apiservice.subscribeString({plc:this.plc, address: this.address})
  }

  change() {
    console.log("changed ", this.value)
  }

  
}
