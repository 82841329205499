<div class="d-flex flex-row px-2 w-100 justify-content-between align-items-center">
    
    <div class="d-flex">
        <div *ngIf="lamp" class="lamp" [ngClass]="{'bg-success': lamp.value, 'bg-danger': !lamp.value}"></div>
        &nbsp;&nbsp;
        <!-- <div *ngIf="status" class="status">
            <span  *ngIf="counterActual && !continuos">
                ({{counterActual.value}})
            </span>
            {{status.value}}
        </div> -->
       
    </div>
    
    <!-- <ng-container *ngFor="let m of model.modes">
        <div *ngIf="m.subscription && m.subscription.value && m.subscription.value.toString() == 'true'" class="text-white">{{m.name}}</div>
    </ng-container> -->
    <!-- <img class="ml-auto" *ngIf="model.modes" src="../../../../assets/{{getActiveModeIcon()}}">

    <div  *ngIf="counterActual && continuos && continuos.value == false" class=" label">
        {{counterActual.value}} 
    </div> -->

    <div *ngIf="!counterSet " class="d-flex flex-column align-items-center">
        <img *ngIf="model.modes" src="../../../../assets/{{getActiveModeIcon()}}">
        <span>
            <span *ngIf="counterActual" class="text-dark">
                {{counterActual.value}} 
                <span *ngIf="model.counterActual.um">{{model.counterActual.um}}</span>
            </span>
            <span *ngIf="status && status.value" class="status text-dark">
                    - {{status.value}}
            </span>
        </span>
    </div>
    
    <div *ngIf="counterSet" class="text-center">
        <div *ngIf="!continuos || !continuos.value" class="text-dark text-center">
            {{counterSet.value}} Pz
        </div>
        <div *ngIf="continuos && continuos.value" class="text-dark text-center">
            Infinito
        </div>
        <div class="w-100">
            <button *ngIf="continuos && continuos.value == false" class="ml-auto" (touchstart)="inc($event)">+</button>
            <button *ngIf="continuos && continuos.value == false" class="" (touchstart)="dec($event)">-</button>
            <button *ngIf="counterSet" class="" (touchstart)="infinite($event)" >&infin;</button>
        </div>
    </div>
    
    <div class="" *ngIf="model.name">{{model.name}}</div>
</div>
