<div class="w-100 h-100">
    <img class="img-fluid align-self-center" src="../../../../assets/map.png" (click)="select(null)" >
    
    <div *ngFor="let l of lamps"
        [ngStyle]="{
            'top': l.y + '%',
            left: l.x + '%',
            width: '24px',
            height: '24px'
            }"    
        style="position:absolute;z-index: 1;border-radius: 10px;"
        class="bg-danger"
        [hidden]="!l.variable.value"
        >
    </div>

    <app-area *ngFor="let c of areas"
    [model]="c"
    [ngStyle]="{
        'top': c.y + '%',
        left: c.x + '%',
        width: c.w + '%',
        height: c.h + '%'
        }"

    [ngClass]="{'selected' : c == selection}" class="{{c.customClass}}"
    (touchstart)="onTouchStart($event, c)"
    (touchend)="onTouchEnd($event, c)"
    ></app-area>

    <app-chain *ngFor="let c of chains"
        [model]="c"
        [ngStyle]="{
            'top': c.y + '%',
            left: c.x + '%',
            width: c.w + '%',
            height: c.h + '%'
            }"
        [ngClass]="{'selected' : c == selection || (selections.indexOf(c) > -1), 'paused' : !c.running, 'highlighted': c.highlighted}"
        (touchstart)="onTouchStart($event, c)"
        (touchend)="onTouchEnd($event, c)"
    ></app-chain>


    <app-conveyor *ngFor="let c of conveyors"
        [model]="c"
        [ngStyle]="{
            'top': c.y + '%',
            left: c.x + '%',
            width: c.w + '%',
            height: c.h + '%'
            }"
        [ngClass]="{'selected':c == selection || (selections.indexOf(c) > -1) , 'paused' : !c.running}"
        class="{{c.customClass}}"
        
        class="bg-muted"
        (touchstart)="onTouchStart($event, c)"
        (touchend)="onTouchEnd($event, c)"
    >
</app-conveyor>
    

    <app-action *ngFor="let c of actions"
    [model]="c"
    [ngStyle]="{
        'top': c.y + '%',
        left: c.x + '%',
        width: c.w + '%',
        height: c.h + '%'
        }"
    [ngClass]="{'paused' : !c.running}"
    class="{{c.customClass}}"
    class="bg-muted"
    ></app-action>

   
    <app-context-menu *ngIf="selection || selections" [machine]="selection" [machines]="selections"></app-context-menu>
</div>

<!-- 
<div *ngIf="mode == 'debug'">
    <img class="img-fluid align-self-center" src="../../../../assets/map.png" (click)="select(null)">
</div>


<div *ngIf="mode == 'config'">
    <div class="row">
        <div class="col-3">
            <ul>
                <li>dasdas</li>
                <li>dasdas</li>
            </ul>
        </div>
        <div class="col">
            dsasd
        </div>
    </div> 
</div> -->