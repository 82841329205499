import { Component, HostBinding, OnInit } from '@angular/core';
import { AbstractMachine } from '../abstract-component';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html'

})
export class AreaComponent extends AbstractMachine {
  
  @HostBinding('class.in-alarm') get inAlarm() { 
    var r = false
    this.alarms.forEach(a => {
      if (a.value == false)
        r = true
      })
      return r
  }

}
