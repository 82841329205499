import { Component, HostBinding, OnInit } from '@angular/core';
import { PLCSubscription } from 'src/app/services/restapi.service';
import { PLCHandle } from 'src/app/services/ws.service';
import { AbstractMachine } from '../abstract-component';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  host: {
    "(click)": "onClick($event)"
  }  
})
export class ActionComponent extends AbstractMachine implements OnInit {
  
  @HostBinding('class.highlight') get h () { return this.running ? this.running.value : false }

  run: PLCHandle;
  running: PLCHandle;

  ngOnInit() {
    super.ngOnInit()
    if (this.model.run) 
      this.run = this.ws.getHandle(this.model.run.name)
    if (this.model.running) 
      this.running = this.ws.getHandle(this.model.running.name)
  }


  onClick(e) {

    if (this.model.run)  {
      this.api.write(this.run, !this.run.value)
      this.running.value = !this.run.value
    }
    else 
      console.log("No run variable to write")
  }

}
