<div class="d-flex flex-row w-100 p-2">

  <div class="ml-3 mr-auto d-flex align-items-center">
    <span class="lead  text-white"><b>{{machine?.name}}</b></span>
  </div>
    
  <div class="ml-3">
    <button *ngFor="let b of pushbuttons"   (touchstart)="press($event,b)" (touchend)="release($event,b)" [ngClass]="{'bg-primary': b.subscription?.value == true}" [title]="b.name">
      <span *ngIf="!b.icon">{{b.name}}</span>
      <span *ngIf="b.icon"><i class="{{b.icon}}"></i> {{b.buttons.length}}</span>
    </button>
  </div>
  
  <div class="ml-3">
    <button *ngFor="let b of machine?.buttons" (click)="click(b)" [ngClass]="{'bg-primary': b.variable.value == true}" [title]="b.name">
      {{b.name}}
    </button>
  </div>
  
  <div class="ml-3">
    <button *ngFor="let s of machine?.switches" (click)="switch(s)"  [title]="s.name" [ngClass]="{'bg-primary': s.variable.value == true} ">
      {{s.name}}
    </button>
  </div>

  <div class="ml-3">
    <button *ngFor="let m of machine?.modes" (click)="select(m.variable)" [ngClass]="{'bg-primary': m?.variable.value == true}" [title]="m.name"> 
      <span *ngIf="!m.icon">{{m.name}}</span>
      <img *ngIf="m.icon" src="../../../../assets/{{m.icon}}"></button>
  </div>



  

</div>